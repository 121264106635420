<template>
  <div class="app-layout__navbar">
    <va-navbar>
      <template v-slot:left>
        <div class="left">
          <va-icon-menu-collapsed
            @click="isSidebarMinimized = !isSidebarMinimized"
            :class="{ 'x-flip': isSidebarMinimized }"
            class="va-navbar__item"
            :color="colors.primary"
          />
          <router-link to="/">
            <b style="color: var(--va-primary)">RCJM</b>
          </router-link>
        </div>
      </template>
      <template v-slot:center>
      </template>
      <template #right>
        <app-navbar-actions
          class="app-navbar__actions md5 lg4"
          :user-name="user.name"
        />
      </template>
    </va-navbar>
  </div>
</template>

<script>
import { useColors } from 'vuestic-ui'

import { useSidebarStore } from '@/stores/sidebar.store'
import { useAuthStore } from '@/stores/auth.store'

import { computed } from 'vue'

import VaIconMenuCollapsed from '@/components/icons/VaIconMenuCollapsed'
import AppNavbarActions from './components/AppNavbarActions'

export default {
  components: { AppNavbarActions, VaIconMenuCollapsed },
  setup() {
    const { getColors } = useColors()
    const colors = computed(() => getColors() )

    const sidebarStore = useSidebarStore();
    const authStore = useAuthStore();

    const isSidebarMinimized = computed({
      get: () => sidebarStore.isSidebarMinimized,
      set: (value) => sidebarStore.updateSidebarCollapsedState(value)
    })

    const user = computed(() => authStore.user)

    return {
      colors,
      isSidebarMinimized,
      user
    }
  },
}
</script>

<style lang="scss">
  .app-layout__navbar {
    .va-navbar {
      box-shadow: var(--va-box-shadow);
      z-index: 2;

      .va-navbar__content {
        flex-direction: row !important;
      }

      .profile-dropdown {
        top: 4px;

        @media (max-width: 950px) {
          justify-content: flex-end;
        }
      }

      @media (max-width: 950px) {
        flex-direction: row;
      }

      &__center {
        @media (max-width: 1200px) {
          .app-navbar__github-button {
            display: none;
          }
        }
        @media (max-width: 950px) {
          .app-navbar__text {
            display: none;
          }
        }
      }

      @media (max-width: 950px) {
        .left {
          width: 100%;
        }
        .color-dropdown {
          display: none !important;
        }
        .app-navbar__actions {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
      }
    }

    .left {
      display: flex;
      align-items: center;
      & > * {
        margin-right: 1.5rem;
      }
      & > *:last-child {
        margin-right: 0;
      }
    }

    .x-flip {
      transform: scaleX(-100%);
    }

    .va-navbar__item {
      cursor: pointer;
    }

    .app-navbar__text > * {
      margin-right: 0.5rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
</style>
