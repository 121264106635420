<template>
  <div class="auth-layout row align-content--center">

    <div class="flex xs12 pa-3 flex-center">
      <router-link class="py-5 flex-center" to="/">
        <img style="width: 100%; max-width: 400px;" :src="require('@/assets/rcms.svg')"/>
      </router-link>
    </div>

    <div class="flex xs12 pa-3">
      <div class="d-flex justify--center">
        <va-card class="auth-layout__card">
          <va-card-content>
            <va-tabs v-model="tabIndex" center>
              <template #tabs>
                <va-tab name="login">{{ $t("auth.login") }}</va-tab>
                <!--<va-tab name="signup">{{ $t("auth.createNewAccount") }}</va-tab>-->
              </template>
            </va-tabs>

            <va-separator />

            <div class="pa-3">
              <router-view />
            </div>
          </va-card-content>
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthLayout",

  components: { },

  data() {
    return {
      selectedTabIndex: 0,
    };
  },

  methods: {
    init () { }
  },

  mounted () {
    this.init();
  },

  computed: {
    tabIndex: {
      set(tabName) {
        this.$router.push({ name: tabName });
      },
      get() {
        return this.$route.name;
      }
    }
  }
};
</script>

<style lang="scss">
.auth-layout {
  min-height: 100vh;
  background-image: linear-gradient(to right, var(--va-background), var(--va-white));

  &__card {
    width: 100%;
    max-width: 600px;
  }

  .flex-end {
    justify-content: flex-end;
  }
}
</style>
