export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'dashboard',
      displayName: 'menu.dashboard',
      permissions: ['admin'],
      meta: {
        icon: 'vuestic-iconset-dashboard',
      },
    },

    {
      displayName: 'menu.general',
      permissions: ['admin', 'general'],
      meta: {
        icon: 'fa-diagram-project',
      },
      children: [
        {
          name: 'general-company',
          displayName: 'menu.company',
          permissions: ['admin', 'company'],
          meta: {
            icon: 'fa-money-check-dollar',
          },
        },
        {
          name: 'general-joboffer',
          displayName: 'menu.joboffer',
          permissions: ['admin', 'joboffer'],
          meta: {
            icon: 'fa-file-contract',
          },
        },
        {
          name: 'general-candidate',
          displayName: 'menu.candidate',
          permissions: ['admin', 'candidate'],
          meta: {
            icon: 'fa-file-contract',
          },
        }
      ]
    },

    {
      name: 'settings',
      displayName: 'menu.settings',
      permissions: ['admin', 'settings'],
      meta: {
        icon: 'settings',
      },
    },

    {
      displayName: 'menu.admin',
      permissions: ['admin'],
      meta: {
        icon: 'fa-screwdriver-wrench',
      },
      children: [
        {
          name: 'admin/user',
          displayName: 'menu.user',
          permissions: ['admin'],
          meta: {
            icon: 'fa-calendar',
          },
        },
        {
          name: 'admin/settings',
          displayName: 'menu.settings',
          permissions: ['admin'],
          meta: {
            icon: 'fa-calendar',
          },
        },
      ]
    },
  ],
}
