import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import AuthLayout from '@/layout/auth-layout.vue'
import AppLayout from '@/layout/app-layout.vue'
import Page404Layout from '@/layout/page-404-layout.vue'

import { useAuthStore } from '@/stores/auth.store'
import { useMutationStore } from '@/stores/mutation.store'

const routes: Array<RouteRecordRaw> = [
  {
    name: 'admin',
    path: '/admin',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin'], 'not-found-simple') },
    children: [
      {
        name: 'admin_entry',
        path: '',
        redirect: { name: 'dashboard' },
      },
      {
        name: 'admin/user',
        path: 'user',
        component: () => import('@/pages/admin/general/user/User.vue'),
      },
      {
        name: 'admin/settings',
        path: 'settings',
        component: () => import('@/pages/admin/general/settings/Settings.vue'),
      },
    ]
  },

  {
    path: '/dashboard',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'dashboard', 'property'], 'not-found-simple') },
    children: [
      {
        name: 'dashboard',
        path: '',
        component: () => import('@/pages/admin/overview/Overview.vue'),
      },
    ]
  },

  {
    path: '/settings',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'settings'], 'not-found-simple') },
    children: [
      {
        name: 'settings',
        path: '',
        component: () => import('@/pages/settings/Settings.vue'),
      },
    ]
  },

  {
    path: '/general',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'general'], 'not-found-simple') },
    children: [
      {
        name: 'general-company',
        path: 'company',
        component: () => import('@/pages/general/company/Company.vue'),
      },
      {
        name: 'general-joboffer',
        path: 'job-offer',
        component: () => import('@/pages/general/job-offers/JobOffer.vue'),
      },
      {
        name: 'general-candidate',
        path: 'candidate',
        component: () => import('@/pages/general/candidate/Candidate.vue'),
      },
    ]
  },

  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('@/pages/auth/login/Login.vue'),
      },
      {
        name: 'signup',
        path: 'signup',
        component: () => import('@/pages/auth/signup/Signup.vue'),
      },
      {
        name: 'recover-password',
        path: 'recover-password',
        component: () => import('@/pages/auth/recover-password/RecoverPassword.vue'),
      },
      {
        path: '',
        redirect: { name: 'login' },
      },
    ],
  },
  {
    path: '/404',
    component: Page404Layout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, [], '') },
    children: [
      {
        name: 'not-found-advanced',
        path: 'not-found-advanced',
        component: () => import('@/pages/404-pages/VaPageNotFoundSearch.vue'),
      },
      {
        name: 'not-found-simple',
        path: 'not-found-simple',
        component: () => import('@/pages/404-pages/VaPageNotFoundSimple.vue'),
      },
      {
        name: 'not-found-custom',
        path: 'not-found-custom',
        component: () => import('@/pages/404-pages/VaPageNotFoundCustom.vue'),
      },
      {
        name: 'not-found-large-text',
        path: '/pages/not-found-large-text',
        component: () => import('@/pages/404-pages/VaPageNotFoundLargeText.vue'),
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    redirect: { name: 'login' },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  //  mode: process.env.VUE_APP_ROUTER_MODE_HISTORY === 'true' ? 'history' : 'hash',
  routes
})

const isAllowed = (to:any, from:any, next:any, permissions:Array<string>, reditectTo:string) => {
  const authStore = useAuthStore()

  if (to.name == 'login') {
    if(authStore?.token) {
      next({ name: '' })
    } else {
      next();
    }
  } else {
    let check = checkPermissions(permissions)

    if(check.allowed) {
      next();
    } else {
      if(authStore?.token) {
        next({ name: reditectTo })
      } else {
        next({ name: 'login' })
      }
    }
  }
}

const checkPermissions = (permissions:Array<string>) => {
  const authStore = useAuthStore()

  // return { allowed: true, redirectTo: null }

  if(authStore?.user?.role?.name == 'admin') {
    return { allowed: true, redirectTo: null };
  }

  if(permissions.length == 0) {
    return { allowed: true, redirectTo: null };
  } else if(permissions.length > 0) {
    if(authStore?.token && authStore?.user?.role) {
      for(let permission of permissions) {
        if(authStore?.user?.role?.permissions.map((data) => { return data.name }).indexOf(permission) > -1) {
          return { allowed: true, redirectTo: null };
        }
      }

      return { allowed: false, redirectTo: '' };
    } else {
      return { allowed: false, redirectTo: 'login' };
    }
  } else {
    return { allowed: false, redirectTo: '' };
  }
}

router.beforeEach(async (to:any, from:any, next:any) => {
  const authStore = useAuthStore()
  const mutationStore = useMutationStore();

  let redirect = mutationStore.auth_guard({name: to?.name, query: to?.query});

  if(!redirect) {
    next(false);
  } else {
    if (to.name == 'login') {
      if(authStore?.token) {
        next();
      } else {
        next();
      }
    } else {
      next();
    }
  }
})

export default router
