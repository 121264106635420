import { createVuestic, useGlobalConfig } from 'vuestic-ui';
import { createApp } from 'vue'
// import { createGtm } from 'vue-gtm'
import { createI18n } from 'vue-i18n'
import { useI18n } from "vue-i18n";
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import { createPinia } from 'pinia'

import vuesticGlobalConfig from './services/vuestic-ui/global-config'

import uuid from 'vue-uuid'

const i18nConfig = {
  locale: 'de',
  fallbackLocale: 'de',
  messages: {
    en: require('@/i18n/en.json'),
    de: require('@/i18n/de.json'),
  },
  numberFormats: require('@/i18n/numbers.json')
}

const app = createApp(App)
const pinia = createPinia()

app.use(router)
app.use(uuid)
app.use(pinia)


const i18n = createI18n(i18nConfig)
app.use(i18n)
app.use(createVuestic({config: vuesticGlobalConfig}))


//console.log(i18n.t.t('rules.is_required'))

app.config.globalProperties.$validationRules = {
  requiredRule: ( error_message:string ) => {
    const rule = (value:any) => {
      if(value === undefined || value === null) {
        return error_message
      } else {
        return ('' + value).length > 0 || error_message
      }
    }
    return rule;
  },

  requiredRuleVisibleNOT: ( error_message:string, item:any, key:string ) => {
    const rule = (value:any) => {
      if(!item[key]) {
        return true
      }

      if(value === undefined || value === null) {
        return error_message
      } else {
        return ('' + value).length > 0 || error_message
      }
    }
    return rule;
  },

  requiredRuleValuesIn: ( error_message:string, item:any, key:string, values:Array<string> ) => {
    const rule = (value:any) => {
      let _check = null;

      try {
        _check = key.split(/"|'|\]|\.|\[/).reduce((s,c)=>c===""?s:s&&s[c], item)
      } catch (error) {
        return true;
      }

      if(values.includes(_check)) {
        if(value === undefined || value === null) {
          return error_message
        } else {
          return ('' + value).length > 0 || error_message
        }
      } else {
        return true
      }
    }
    return rule;
  }
}

app.mount('#app')

