// Pinia Store
import { defineStore } from 'pinia'

interface State {
  not_saved: boolean
  show: boolean
  to: any
}

export const useMutationStore = defineStore('mutation', {
  state: (): State => ({
    not_saved: false,
    to: null,
    show: false,
  }),

  actions: {
    // MUTATIONS
    start_editing () {
      this.not_saved = true;
    },

    end_editing () {
      this.not_saved = false;
    },

    // ACTIONS

    auth_guard (to:any) {
      if(this.not_saved) {
        this.show = true;
        this.to = to;

        return false
      } else {
        this.show = false;
        this.to = false;

        return true;
      }
    }
  },

  getters: { },
});
