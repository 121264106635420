<template>
  <va-accordion class="sidebar-accordion va-sidebar__menu__inner" v-model="accordionValue" multiply>
    <div v-for="(route, idx) in items" :key="idx">
      <va-collapse v-if="this.authStore.$state.user.role.name == 'admin' || check_permission(route.permissions, this.authStore.$state.user.role.permissions.map((data) => { return data.name}))">
        <template #header>
          <va-sidebar-item :active="isRouteActive(route)" :to="route.children ? undefined : { name: route.name }">
            <va-sidebar-item-content>
              <va-icon :name="route.meta.icon" :class="'va-sidebar-item__icon'"/>

              <va-sidebar-item-title>
                {{ $t(route.displayName) }}
              </va-sidebar-item-title>

              <va-icon v-if="route.children" :name="accordionValue[idx] ? 'expand_less' : 'expand_more'" />
            </va-sidebar-item-content>
          </va-sidebar-item>
        </template>
        <div v-for="(child, index) in route.children" :key="index">
          <div v-if="this.authStore.$state.user.role.name == 'admin' || check_permission(route.permissions, this.authStore.$state.user.role.permissions.map((data) => { return data.name}))">
            <va-sidebar-item :active="isRouteActive(child)" :to="{ name: child.name }">
              <va-sidebar-item-content>
                <div class="va-sidebar-item__icon"></div>

                <va-sidebar-item-title>
                  {{ $t(child.displayName) }}
                </va-sidebar-item-title>
              </va-sidebar-item-content>
            </va-sidebar-item>
          </div>
        </div>
      </va-collapse>
    </div>
  </va-accordion>
</template>

<script>
import { useAuthStore } from '@/stores/auth.store'

import { checkPermission }  from './checkPermission.js'

export default {
  name: "AppMenuAccordion",

  props: {
    items: { type: Array, default: () => [] }
  },

  data () {
    const authStore = useAuthStore();

    return {
      authStore,
      accordionValue: []
    }
  },


  mounted () {
    this.accordionValue = this.items.map(i => this.isItemExpanded(i));
  },

  methods: {
    isGroup(item) {
      return !!item.children;
    },
    isRouteActive(item) {
      return item.name === this.$route.name;
    },
    isItemExpanded(item) {
      if (!item.children) {
        return false;
      }

      const isCurrentItemActive = this.isRouteActive(item);
      const isChildActive = !!item.children.find(child =>
        child.children ? this.isItemExpanded(child) : this.isRouteActive(child)
      );

      return isCurrentItemActive || isChildActive;
    },
    check_permission( r_permissions, u_permissions ) {
      return checkPermission(r_permissions, u_permissions)
    },
  },
};
</script>

<style lang="scss" scoped>
  .sidebar-accordion {
    .va-sidebar-item--active {
      .va-sidebar__title, .va-icon {
        color: #fff;

      }

      &:hover {
        .va-sidebar__title, .va-icon {
          color: #000;
        }
      }
    }
  }
</style>
