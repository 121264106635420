<template>
  <div class="app-layout">
    <navbar />
    <div class="app-layout__content">
      <div class="app-layout__sidebar-wrapper" :class="{ minimized: isSidebarMinimized }">
        <div v-if="isFullScreenSidebar" class="d-flex justify--end">
          <va-button
            class="px-4 py-4 close-button"
            icon="close"
            preset="plain"
            @click="onCloseSidebarButtonClick"
            style="height: 60px;"
          />
        </div>
        <sidebar
          :width="sidebarWidth"
          :minimized="isSidebarMinimized"
          :minimizedWidth="sidebarMinimizedWidth"
        />
      </div>
      <div class="app-layout__page">
        <div class="layout fluid gutter--xl">
          <router-view/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { onBeforeRouteUpdate } from 'vue-router';

import { useSidebarStore } from '@/stores/sidebar.store'
import { useSocketStore } from '@/stores/socket.store'
import { useAuthStore } from '@/stores/auth.store'

import Sidebar from '@/components/sidebar/Sidebar';
import Navbar from '@/components/navbar/Navbar.vue';

export default defineComponent({
  name: 'app-layout',

  components: {
    Navbar, Sidebar
  },

  setup() {
    const sidebarStore = useSidebarStore();

    const mobileBreakPointPX = 640
    const tabletBreakPointPX = 768

    const sidebarWidth = ref('16rem')
    const sidebarMinimizedWidth = ref(undefined)

    const isMobile = ref(false)
    const isTablet = ref(false)
    const isSidebarMinimized = computed(() => sidebarStore.isSidebarMinimized)
    const checkIsTablet = () => window.innerWidth <= tabletBreakPointPX
    const checkIsMobile = () => window.innerWidth <= mobileBreakPointPX

    const onResize = () => {
      sidebarStore.updateSidebarCollapsedState(checkIsTablet())

      isMobile.value = checkIsMobile()
      isTablet.value = checkIsTablet()
      sidebarMinimizedWidth.value = isMobile.value ? '0' : '4rem'
      sidebarWidth.value = isTablet.value ? '100%' : '16rem'
    }

    onMounted(() => {
      window.addEventListener('resize', onResize)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', onResize)
    })

    onBeforeRouteUpdate(() => {
      if (checkIsTablet()) {
        // Collapse sidebar after route change for Mobile
        sidebarStore.updateSidebarCollapsedState(true)
      }
    })

    onResize()

    const isFullScreenSidebar = computed(() => isTablet.value && !isSidebarMinimized.value)

    const onCloseSidebarButtonClick = () => {
      sidebarStore.updateSidebarCollapsedState(true)
    }

    return {
      sidebarStore,
      isSidebarMinimized,
      sidebarWidth, sidebarMinimizedWidth,
      isFullScreenSidebar, onCloseSidebarButtonClick
    }
  },

  mounted () {
    const socket = useSocketStore();
    const authStore = useAuthStore();

    socket.socket.on('payment-success', async (...args) => {
      this.$vaToast.init({ message: `Player [${ args[0]?.pid }] paid!`, color: 'success' });
    });

    this.$vaToast.init({ message: `Hello ${ authStore?.user?.name }!`, color: 'success' });
  }
})
</script>

<style lang="scss">
$mobileBreakPointPX: 640px;
$tabletBreakPointPX: 768px;

.layout {
  min-height: 100%;
  height: 100%;
}

.app-layout {
  height: 100vh;
  display: flex;
  flex-direction: column;
  &__navbar {
    min-height: 4rem;
  }

  .close-button {
    .va-icon {
      font-size: 20px !important;
      color: #222 !important;
    }
  }

  &__content {
    display: flex;
    height: calc(100vh - 4rem);
    flex: 1;

    @media screen and (max-width: $tabletBreakPointPX) {
      height: calc(100vh - 6.5rem);
    }

    .app-layout__sidebar-wrapper {
      position: relative;
      height: 100%;
      background: var(--va-white);

      @media screen and (max-width: $tabletBreakPointPX) {
        &:not(.minimized) {
          width: 100%;
          height: 100%;
          position: fixed;
          top: 0;
          z-index: 999;
        }

        .va-sidebar:not(.va-sidebar--minimized) {
          // Z-index fix for preventing overflow for close button
          z-index: -1;
          .va-sidebar__menu {
            padding: 0;
          }
        }
      }
    }
  }
  &__page {
    flex-grow: 2;
    overflow-y: scroll;
  }
}
</style>
