<template>
  <va-dropdown
    class="profile-dropdown"
    v-model="isShown"
    :prevent-overflow="false"
    position="bottom-end"
    :offset="[24, 0]"
  >
    <template #anchor>
      <span class="profile-dropdown__anchor">
        <slot/>
        <va-icon
          class="px-2"
          :name="isShown ? 'angle_up' :'angle_down'"
          :color="theme.primary"
        />
      </span>
    </template>
    <va-dropdown-content class="profile-dropdown__content">
      <!--
      <div>{{ authStore?.user?.name }}</div>
      <va-list-item
        v-for="option in options"
        :key="option.name"
      >
        <router-link
          :to="{name: option.redirectTo}"
          class="profile-dropdown__item"
        >
          {{ $t(`user.${option.name}`) }}
        </router-link>
      </va-list-item>
      -->

      <va-list-item class="logout-section my-3">
        <va-button v-on:click="logout">Logout</va-button>
      </va-list-item>
    </va-dropdown-content>
  </va-dropdown>
</template>

<script>
import { useGlobalConfig } from 'vuestic-ui'

// import store from "@/store";
import { useAuthStore } from '@/stores/auth.store'

export default {
  name: 'profile-section',
  setup() {
    const authStore = useAuthStore();

    return {
      authStore
    }
  },
  data () {
    return {
      isShown: false,
    }
  },
  props: {
    options: {
      type: Array,
      default: () => [
        {
          name: 'profile',
          redirectTo: '',
        },
      ],
    },
  },
  computed: {
    theme() { return useGlobalConfig().getGlobalConfig() },
  },
  methods: {
    logout () {
      this.authStore.LOGOUT().then(() => {
        this.$router.push({ name: 'login' })
      });
    }
  },
}
</script>

<style lang="scss">
.logout-section {
  .va-list-item__inner {
    display: flex;
    justify-content: center;
  }
}

.profile-dropdown {
  cursor: pointer;
  width: 200px;

  .va-dropdown-popper__anchor {
    display: flex;
    justify-content: flex-end;
  }

  &__content {
    width: 8rem;
  }

  &__item {
    display: block;
    color: var(--va-gray);

    &:hover,
    &:active {
      color: var(--va-primary);
    }
  }

  .va-dropdown__anchor {
    display: inline-block;
  }
}
</style>
